import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import { createPopper } from '@popperjs/core';
import 'bootstrap';
import 'mmenu-js';
import 'mhead-js';


$(function () {

        //====================== Footer ================================//
    $('.footerHdl').on("click tap", function () {

        if (window.matchMedia('(max-width: 768px)').matches) {


            if ($(this).hasClass('on')) {
                $(this).removeClass('on');
            } else {
                $(this).addClass('on');
            }
            $(this).nextUntil('').toggle();
        }
    });


    // Formular-Elemente
    var formselectors = {
        input: ".js-formgroup input[type=text],.js-formgroup input[type=number],.js-formgroup input[type=email],.js-formgroup input[type=password], .js-formgroup input[type=tel],.js-formgroup textarea",
        elementWrapper: ".js-formgroup"
    };

    var $inputArray = $(formselectors.input);
    if ($inputArray.length) {
        $inputArray.map(function (key, val) {
            if (val.value) {
                $(val.closest(formselectors.elementWrapper)).addClass("is-filled");
            }
        })


        $(formselectors.input).focusin(function (e) {
            var $self = $(e.currentTarget);
            $self.closest(formselectors.elementWrapper).addClass("focus");
        });


        $(formselectors.input).focusout(function (e) {
            var $self = $(e.currentTarget);
            $self.closest(formselectors.elementWrapper).removeClass("focus is-filled");
            if (this.value) {
                $self.closest(formselectors.elementWrapper).addClass("is-filled");
            }
        });
    }

});




$(function () {
            new Mmenu( "#my-menu", {
                // options
                extensions: {
                        all: ["theme-white", "pagedim-black"],
                        "(max-width: 767px)": ["fx-menu-slide"]
                    },
                
                       "navbars": [
                           {
                             "position": "top",
                             "content": [
                               "<a href='/' class='mmNavbarLogo'><img src='/images/logo_mit.svg'></a>"
                             ]
                          },
                          {
                             "position": "top",
                             "content": [
                                "searchfield"
                             ]
                          },
                          {
                             "position": "bottom",
                             "content": [
                                "<a class='fa fa-envelope' href='#/'></a>",
                                "<a class='fa fa-twitter' href='#/'></a>",
                                "<a class='fa fa-facebook' href='#/'></a>"
                             ]
                          }
                       ],
                counters: false,
                iconPanels: {
                        add: !0,
                        hideDivider: !0,
                        hideNavbar: !0,
                        visible: "first"
                    },
                sidebar: {
                        /* collapsed: {
                            use: 768,
                            hideNavbar: !0,
                            hideDivider: !0
                        }, */
                        /* expanded: {
                            use: 900,
                            initial: "open"
                        } */
                    }
            }, {
                // configuration
                offCanvas: {
                clone:true,
                    page: {
                         selector: "#my-page"
                    }
                },
                scrollBugFix: {
                    // scroll bug fix configuration
                },
                classNames:{
                    selected:"active"
                },
            });

            new Mhead( "#my-header", {
      
            }); 

            var $document = $(document),
    $element = $('#my-header'),
    className = 'hasScrolled';

$document.on('scroll',function() {
  if ($document.scrollTop() >= 50) {
    // user scrolled 50 pixels or more;
    // do stuff
    $element.addClass(className);
  } else {
    $element.removeClass(className);
  }
});
        }
    );

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})